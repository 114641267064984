<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right" >
      <el-breadcrumb-item>广告管理</el-breadcrumb-item>
      <el-breadcrumb-item>标签管理</el-breadcrumb-item>
    </el-breadcrumb>
    <br />
    <el-row>
      <el-button type="primary" @click="handleCreate">添加标签</el-button>

      <!-- <el-select v-model="listQuery.appId"  placeholder="app" style="margin-left: 30px" @change="qappIdChange" >
        <el-option
            v-for="item in appOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
        </el-option>
      </el-select> -->

    </el-row>

<!--表单 BEGIN -->
    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
      <el-form ref="dataForm" :rules="rules" :model="typeFomr" label-position="left" label-width="100px" style="width: 600px; margin-left:50px;">
        <!-- <el-form-item label="单点运营" prop="openSingle">
          <el-select v-model="temp.openSingle" style="width:500px" placeholder="是否开启单点精准运营">
            <el-option
                v-for="item in attributeOptions"
                :key="item.id"
                :label="item.label"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item> -->

        <el-form-item label="标签名称" prop="name">
          <el-input v-model="typeFomr.name" />
        </el-form-item>

        <el-form-item label="标签价格" prop="price">
          <el-input v-model="typeFomr.price"/>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="dialogStatus==='create'?createData():updateData()">
          保存
        </el-button>
      </div>
    </el-dialog>
    <!--表单 END -->
    <br />
    <div style="background: #fff ">
    <el-table ref="singleTable" :data="tableData" border fit style="width: 100%;height: 100%">
      <el-table-column fixed fit align="center" type="index" min-width="20" />
      <el-table-column prop="id" label="标签ID" min-width="50" />
      <el-table-column prop="name" label="标签名称" min-width="50" />
      <el-table-column prop="price" label="标签价格" min-width="50" />
      <el-table-column prop="createTime" label="创建时间" min-width="50" />
      <el-table-column label="操作" align="center" min-width="70" >
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="primary"
              @click="handleUpdate(scope.$index, scope.row)">编辑</el-button>
          <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination style="background: #fff ;margin-top: 20px;margin-left: 10px"
                   background @current-change="handleCurrentChange" layout="total,prev, pager, next" :page-size="listQuery.size" :total="total">
    </el-pagination>
    <div style="height: 30px;" />
    </div>

  </div>
</template>

<script>
import { tagAdd, tagList, tagEdit, tagDel, delApi } from '@/api/ads'
export default {
  name: "ads",
//   components: {AdsDetailConfig, AdsTotalConfig ,todayPd,zqPd},
  data() {
    const typeNameRules = (rule,value,callback) =>{
        const reg = /(^\s+)|(\s+$)|\s+|[·！/ \\ #￥（——）：；“”‘、，|《。》？、【】[\]]/g
        if(value !== '') {
            if (reg.test(value)) {
                callback(new Error('请输入正确的标签名称'))
            } else {
                callback()
            }
        } else {
            callback(new Error('不能为空'))
        }
    }
    const priceRules = (rule,value,callback) =>{
        if(value !== '') {
            let digit = value.toString().split('.')[1].length
            if (value <= 0 || digit > 2) {
                callback(new Error('请输入正确的标签价格'))
            } else {
                callback()
            }
        } else {
            callback(new Error('不能为空'))
        }
    }
    return {
      total: 0,
      typeFomr:{
          name:'',
          price:''
      },
      listQuery: {
        current: 1,
        size: 10,
        appId:''
      },
      dialogFormVisible: false,
      dialogFormTotalConfig:false,
      dialogFormDetailConfig:false,

      dialogtodayPd:false,
      dialoglistPd:false,

      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '添加'
      },
      rules: {
        name: [{ required: true, validator: typeNameRules, trigger: 'change' }],
        price: [{ required: true, validator: priceRules, trigger: 'change' }],
      },
      temp: {
        id: undefined,
        adName: '',
        adType:'',
        appId: '',
        openSingle: '',
        versionName: '',
        sign: '',

      },
      tableData: [],
      appOptions:[],
      adTypeOptions:[],
      attributeOptions:[{
        id: 1,
        label: '开启'
      },{
        id: 2,
        label: '关闭'
      }],
    }
  },
  created() {
      this.tagList()
  },
  methods:{
    tagList(){
      tagList({current: 1,size: 1000}).then(response => {
          if(response.code === 1) {
              this.tableData = response.body.records
              this.total = response.body.total
          }
      })
    },
    // getInitOptions(){
    //   listAllApi({current: 1,size: 1000,}).then(response => {
    //     this.appOptions = response.body.records
    //   })
    // },
    // getList() {
    //   this.listLoading = true
    //   listApi(this.listQuery).then(response => {
    //     this.tableData = response.body.records
    //     this.total = response.body.total
    //     // Just to simulate the time of the request
    //     setTimeout(() => {
    //       this.listLoading = false
    //     }, 1.5 * 1000)
    //   })
    // },

    handleCurrentChange(val) {
      this.listQuery.current = val;
      this.tagList()
    },
    handleCreate() {
      this.resetTemp()
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          tagAdd(this.typeFomr).then(() => {
            // this.getList();
            this.dialogFormVisible = false
            this.$notify({
              title: 'Success',
              message: '新增成功',
              type: 'success',
              duration: 2000
            })
            this.typeFomr = {
                name:'',
                price:''
            }
            this.tagList()
          })
        }
      })
    },
    resetTemp() {
      this.temp = {
        id: undefined,
        adName: '',
        adType:'',
        appId: '',
        openSingle: '',
        versionName: '',
        sign: '',
      }
    },
    handleContant(index, row) {
      console.info(index,row);
    },
    handleUpdate(index, row) {
      this.typeFomr = Object.assign({}, row)
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    updateData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
            let typeFomr = {
                id:this.typeFomr.id,
                name:this.typeFomr.name,
                price:this.typeFomr.price
            }
          tagEdit(typeFomr).then((res) => {
            // const index = this.tableData.findIndex(v => v.id === this.temp.id)
            // this.tableData.splice(index, 1, this.temp)
            if(res.code === 1) {
                this.dialogFormVisible = false
                this.$notify({
                title: 'Success',
                message: '修改成功',
                type: 'success',
                duration: 2000
                })
                this.tagList()
            }
          })
        }
      })
    },
    handleDelete(index, row) {
      console.log(index, row);
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        tagDel({id: row.id}).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.tagList()
        })

      }).catch(() => {});
    }
  }
}

</script>

<style scoped>


</style>
